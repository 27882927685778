import Vue from 'vue'
import VueRouter from 'vue-router'
import MainUserVue from '@/layout/mainUser.vue'
Vue.use(VueRouter)
const Base64 = require('js-base64').Base64


export const routes = [
  {
    path: '/',
    name: 'homePage | PaperSpotlight',
    hidden: true,
    // component: () => import('../views/information/information.vue')
    component: () => import('../views/main/main.vue')
  },
  {
    path: '/homePage',
    name: 'homePage | PaperSpotlight',
    hidden: true,
    meta:{keepAlive:false},
    component: () => import('../views/main/main.vue')
  },
  
  {
    path: '/login',
    name: 'login | PaperSpotligh',
    hidden: true,
    meta:{keepAlive:false},
    component: () => import('../views/login.vue')
  },
  {
    path: '/register',
    name: 'register | PaperSpotligh',
    hidden: true,
    meta:{keepAlive:false},
    component: () => import('../views/register.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword | PaperSpotligh',
    hidden: true,
    meta:{keepAlive:false},
    component: () => import('../views/resetPassword.vue')
  },
  {
    path: '/teamsOfService',
    name: 'teamsOfService | PaperSpotligh',
    hidden: true,
    meta:{keepAlive:false},
    component: () => import('../views/teamsOfService/index.vue')
  },
  {
    path: '/unsubscribeEmail',
    name: 'unsubscribeEmail | PaperSpotligh',
    hidden: true,
    meta:{keepAlive:false},
    component: () => import('../views/unsubscribeEmail/index.vue')
  },
  // {
  //   path: '/information',
  //   name: '登陆',
  //   hidden: true,
  //   meta:{keepAlive:false},
  //   component: () => import('../views/information/information.vue')
  // },
  // {
  //   path: '/operateFlow',
  //   name: '流程',
  //   hidden: true,
  //   component: () => import('../views/operateFlow/index.vue')
  // },
  {
    path: '/information',
    name: 'information | PaperSpotligh',
    type: 'home',
    component:  () => import('../views/information/information.vue'),
    redirect:'/userInfor',
    children: [
      {
        path: '/status',
        name: 'status | PaperSpotligh',
        icon: 'el-icon-tickets',
        isChild: false, 
        role: '14',
        meta:{keepAlive:true},
        component: () => import('../views/information/common/needed.vue')
      },
      {
        path: '/userInfor',
        name: 'userInfor | PaperSpotligh',
        icon: 'el-icon-tickets',
        isChild: false, 
        role: '14',
        meta:{keepAlive:true},
        component: () => import('../views/information/common/register.vue')
      },
      {
        path: '/account',
        name: 'account | PaperSpotligh',
        icon: 'el-icon-tickets',
        isChild: false, 
        role: '14',
        meta:{keepAlive:false},
        component: () => import('../views/information/common/accountInfor.vue')
      },
      {
        path: '/article',
        name: 'article | PaperSpotligh',
        icon: 'el-icon-tickets',
        isChild: false, 
        role: '14',
        meta:{keepAlive:false},
        component: () => import('../views/information/common/article.vue')
      },
      {
        path: '/package',
        name: 'package | PaperSpotligh',
        icon: 'el-icon-tickets',
        isChild: false, 
        role: '14',
        meta:{keepAlive:false},
        component: () => import('../views/information/common/package.vue')
      },
      {
        path: '/pushPrefer',
        name: 'pushPrefer | PaperSpotligh',
        icon: 'el-icon-tickets',
        isChild: false, 
        role: '14',
        meta:{keepAlive:false},
        component: () => import('../views/information/common/pushPrefer.vue')
      },
      {
        path: '/instrumentPanel',
        name: 'instrumentPanel | PaperSpotligh',
        icon: 'el-icon-tickets',
        isChild: false, 
        role: '14',
        meta:{keepAlive:false},
        component: () => import('../views/information/common/instrumentPanel.vue')
      },
      {
        path: '/personnerProfile',
        name: 'personnerProfile | PaperSpotligh',
        icon: 'el-icon-tickets',
        isChild: false, 
        role: '14',
        meta:{keepAlive:false},
        component: () => import('../views/information/common/personnerProfile.vue')
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// 全局路由构造函数，判断是否登录和要跳转到页面
router.beforeEach((to, from, next) => {
    // const localUser = localStorage.getItem('thesis-token');
    // let token = ''
    // if(localUser){
    //   const localToken = Base64.decode(localUser).split('----');
    //   token = localToken[1].replace(/\d{4}$/, '');
    //   const createTime = localToken[0];
    //   if(createTime && (new Date() - createTime) > (3600 * 1000 * 6 )) {
    //     localStorage.removeItem('thesis-token');
    //     token=''
    //   }
    // }
    // next()

  console.log(12344,to)
  
  const localUser = localStorage.getItem('thesis-token');
  let token = ''
  if(localUser){
    const localToken = Base64.decode(localUser).split('----');
    token = localToken[1].replace(/\d{4}$/, '');
    const createTime = localToken[0];
    if(createTime && (new Date() - createTime) > (3600 * 1000 * 6 )) {
      localStorage.removeItem('thesis-token');
      localStorage.removeItem('userInfo');
      token=''
    }
  }
  // document.title = to.name
  if (to.path !== '/login'&& to.path !== '/' && to.path !=='/homePage' && to.path !== '/register' && to.path !='/resetPassword' && to.path != '/teamsOfService' && to.path != '/unsubscribeEmail') {
    console.log(344)
    if (!token) {
      next('/login')
    } else {
      next()
    }
  }else{
    next()

    console.log(455)
  }



  // }

  // if (to.path !== '/login' && to.path !== '/' && to.path !=='homePage' &&to.path !== 'register') {
  //   const localUser = localStorage.getItem('tesis_token');
  //   let token = ''
  //   if(localUser){
  //     const localToken = Base64.decode(localUser).split('----');
  //     token = localToken[1].replace(/\d{4}$/, '');
  //     const createTime = localToken[0];
  //     if(createTime && (new Date() - createTime) > (3600 * 1000 * 24)) {
  //       localStorage.removeItem('user-ca');
  //       token=''
  //       next('/login')
  //     }
  //   }
  //   if (!token) {
  //     next('/login')
  //   } else {
  //     next()
  //   }
  // } 

  // else{
  //   console.log(123)
  //   next()
  // }
})
export default router
